import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

import Layout from "../../../components/Layout";
import BlogGallery from "../../../createdpages/blog/BlogGallery/BlogGallery";

const NewsTag = ({data}) => {
  const tagPosts = data.sanityPresspage.posts.filter((post) => {
    if (post.post_tag.indexOf("📜 News") >= 0) {
      return true;
    }
    return false;
  });
  return (
    <Layout seoData={{title: "Mason | News"}}>
      <div className='full-bleed layouted bg-gray-200'>
        <div className='tag-header flex flex-col md:flex-row items-center mt-36 mb-12'>
          <div className='tag-icon mr-4 p-4 shadow-md'>
            <StaticImage
              src='https://media.kubric.io/api/assetlib/0c747f7e-e5e3-4f07-a892-eca5dee0d914.png'
              alt='img'
            />
          </div>
          <div className='tag-content w-full md:w-2/4 p-4 md:px-0'>
            <h2 className='md:text-left'>📜 News</h2>
            <span>Catchup on the lastest news</span>
          </div>
        </div>
        <BlogGallery data={tagPosts} post_type='link' />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    sanityPresspage {
      posts {
        author_dp {
          asset {
            gatsbyImageData
          }
        }
        authorAlt
        author
        post_tag
        title
        url
        img {
          asset {
            gatsbyImageData
          }
        }
        alt
      }
    }
  }
`;

export default NewsTag;
